.CodeMirror {
  height: 400px;
}

.editor-toolbar.fullscreen,
.CodeMirror-fullscreen {
  padding-left: 15%;
}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZkc3RhbmRhcmQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBIiwiZmlsZSI6InZkc3RhbmRhcmQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLkNvZGVNaXJyb3Ige1xuICBoZWlnaHQ6IDQwMHB4O1xufVxuXG4uZWRpdG9yLXRvb2xiYXIuZnVsbHNjcmVlbixcbi5Db2RlTWlycm9yLWZ1bGxzY3JlZW4ge1xuICBwYWRkaW5nLWxlZnQ6IDE1JTtcbn1cblxuIl19 */